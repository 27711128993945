import React from 'react';
import { graphql } from 'gatsby';
import { MessageDiv, StyledArticle } from '../styles';
import { StyledResponsiveTable, StyledTable } from '../styles/kontakt';

import { Layout } from '../components/Layout';
import { StyledH2 } from '../styles/common';
import { SEO } from '../components/SEO';

export function Head() {
	return <SEO title="Kontakt" description="Dane kontaktowe." />;
}

function KontaktPage({ data }: any) {
	const { allMarkdownRemark } = data;
	const { edges } = allMarkdownRemark;
	const edge = edges[0];
	const { node } = edge;
	const { id, html, frontmatter } = node;
	const { title, color } = frontmatter;

	return (
		<Layout>
			<StyledArticle color={color} key={id}>
				<StyledH2 as="h1" color={color}>
					{title}
				</StyledH2>
				<MessageDiv dangerouslySetInnerHTML={{ __html: html }} />
				<StyledResponsiveTable>
					<StyledTable>
						<thead>
							<tr>
								<th>Adres</th>
								<th className="telfax">Telefon</th>
								<th className="telfax">Fax</th>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									<address>
										Dział Nadzoru Ruchu
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
										<br />
										<br />
										Centrala Nadzoru Ruchu
										<br />
										Zgłoszenie awarii, kolizji, wypadku
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249476">(52) 324-94-76</a>
									<br />
									<a href="tel:523249478">(52) 324-94-78</a>
									<br />
									<br />
									<br />
									<a href="tel:523249430">(52) 324-94-30</a>
									<br />
									<a href="tel:19285">19285</a>
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Kancelaria Spółki - reklama
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249424">(52) 324-94-24</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Dział Kadr i Płac
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
										<br />
										<br />
										Kadry - kierowcy/motorniczy
										<br />
										Kadry - administracja/zaplecze
										<br />
										Płace
									</address>
								</td>
								<td className="telfax">
									<br />
									<br />
									<br />
									<br />
									<a href="tel:523249414">(52) 324-94-14</a>
									<br />
									<a href="tel:523249440">(52) 324-94-40</a>
									<br />
									<a href="tel:523249495">(52) 324-94-95</a>
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Główny Księgowy
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249444">(52) 324-94-44</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Dział Ekonomiczno-Księgowy
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249499">(52) 324-94-99</a>
									<br />
									<a href="tel:523249406">(52) 324-94-06</a>
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Dział Zaopatrzenia i Magazynów
										<br />
										Magazyn
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249459">(52) 324-94-59</a>
									<br />
									<a href="tel:523249404">(52) 324-94-04</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>
							<tr>
								<td>
									<address>
										Dział Zamówień Publicznych
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249449">(52) 324-94-49</a>
									<br />
									<a href="tel:523249496">(52) 324-94-96</a>
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>
							<tr>
								<td>
									<address>
										Zakład Eksploatacji
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249453">(52) 324-94-53</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Dział Eksploatacji Autobusów
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249427">(52) 324-94-27</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Dział Eksploatacji Tramwajów
										<br />
										ul. Torunska 278
										<br />
										85-831 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249468">(52) 324-94-68</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Zakład Autobusowy
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249450">(52) 324-94-50</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax">
									<a href="fax:523249451">(52) 324-94-51</a>
									<br />
									<br />
									<br />
								</td>
							</tr>

							<tr>
								<td>
									<address>
										Stacja Kontroli Pojazdów
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249400">(52) 324-94-00</a>
									<br />
									<a href="tel:523249410">
										(52) 324-94-10
										<br />
										wew. 269, 270
									</a>
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Dział Techniczno-Zaopatrzeniowy (autobusy)
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249409">(52) 324-94-09</a>
									<br />
									<a href="fax:523249415">(52) 324-94-15</a>
									<br />
									<a href="tel:523249463">(52) 324-94-63</a>
								</td>
								<td className="telfax">
									<a href="fax:523249454">(52) 324-94-54</a>
									<br />
									<br />
									<br />
								</td>
							</tr>

							<tr>
								<td>
									<address>
										Zakład Tramwajowy
										<br />
										ul. Torunska 278
										<br />
										85-831 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249465">(52) 324-94-65</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax">
									<a href="fax:523249466">(52) 324-94-66</a>
									<br />
									<br />
									<br />
								</td>
							</tr>

							<tr>
								<td>
									<address>
										Dział Techniczno-Zaopatrzeniowy (tramwaje)
										<br />
										ul. Toruńska 278
										<br />
										85-831 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249452">(52) 324-94-52</a>
									<br />
									<a href="tel:523249469">(52) 324-94-69</a>
									<br />
									<a href="tel:523249481">(52) 324-94-81</a>
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Wydział Infrastruktury Torowo-Sieciowej
										<br />
										ul. Toruńska 278
										<br />
										85-831 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249475">(52) 324-94-75</a>
									<br />
									<a href="tel:523249472">(52) 324-94-72</a>
									<br />
									<a href="fax:523249471">(52) 324-94-71</a>
								</td>
								<td className="telfax">
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>

							<tr>
								<td>
									<address>
										Pogotowie Sieci
										<br />
										ul. Toruńska 278
										<br />
										85-831 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249474">(52) 324-94-74</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Pogotowie Torów
										<br />
										ul. Toruńska 278
										<br />
										85-831 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249473">(52) 324-94-73</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Dział Inwestycji i Remontów
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249442">(52) 324-94-42</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Dział Regulacji Ruchu
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249412">(52) 324-94-12</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax" />
							</tr>

							<tr>
								<td>
									<address>
										Ochrona Danych Osobowych
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
										<br />
										<a href="mailto: iod@mzk.bydgoszcz.pl">
											iod@mzk.bydgoszcz.pl
										</a>
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249410">
										(52) 324-94-10
										<br />
										wew. 234
									</a>
									<br />
									<br />
									<br />
								</td>
								<td className="telfax">
									<br />
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>

							<tr>
								<td>
									<address>
										Zarząd Spółki
										<br />
										ul. Inowrocławska 11
										<br />
										85-153 Bydgoszcz
										<br />
										<a href="mailto: zarz@mzk.bydgoszcz.pl">
											zarz@mzk.bydgoszcz.pl
										</a>
									</address>
								</td>
								<td className="telfax">
									<a href="tel:523249435">(52) 324-94-35</a>
									<br />
									<br />
									<br />
									<br />
								</td>
								<td className="telfax">
									<a href="fax:523249438">(52) 324-94-38</a>
									<br />
									<br />
									<br />
									<br />
								</td>
							</tr>
						</tbody>
					</StyledTable>
				</StyledResponsiveTable>
			</StyledArticle>
		</Layout>
	);
}
export default KontaktPage;

export const pageQuery = graphql`
	query {
		allMarkdownRemark(filter: { frontmatter: { menu: { eq: "kontakt" } } }) {
			edges {
				node {
					id
					html
					frontmatter {
						slug
						title
						color
					}
				}
			}
		}
	}
`;
