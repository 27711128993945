import styled from 'styled-components';

export const StyledResponsiveTable = styled.div`
	box-sizing: border-box;
	margin: 0 16px;
	max-width: calc(80ch - 32px);
	overflow-x: auto;
	width: calc(100vw - 48px);
	@media all and (min-width: 940px) {
		width: calc(100vw - 290px - 16px - 64px);
	}
`;

export const StyledTable = styled.table`
	border-collapse: collapse;
	border-color: ${(props) => props.theme.borderColor};
	border-spacing: 0;
	border-style: solid;
	border-width: 1px;
	margin-bottom: 16px;
	max-width: 100%;
	width: 100%;
	tr:nth-of-type(odd) {
		background-color: #f8f8f8;
	}
	td,
	th {
		border-collapse: collapse;
		border-color: ${(props) => props.theme.borderColor};
		border-spacing: 0;
		border-style: solid;
		border-width: 1px;
		padding: 8px;
		text-align: left;
		white-space: nowrap;
	}
	td.telfax,
	th.telfax {
		text-align: center;
		a {
			text-decoration: none;
		}
	}
`;
